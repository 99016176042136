@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html {
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: 1.2rem;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    background-color: var(--background-color);
}

section {
    margin-bottom: 3rem;
}

a {
    color: #1b8798;
    text-decoration: underline;
}

video,
iframe {
    width: 100%;
    box-shadow: var(--shadows);
    margin: 0px auto;
}

svg {
    margin: auto;
}

.caption {
    position: relative;
    font-size: 0.9rem;
    text-align: center;
    margin: auto;
}

:root {
    --background-color: #121212 !important;
    --secondary-bg: #191919 !important;
    --text: #fff !important;
    --shadows: 3px 3px 10px rgba(0, 0, 0, 0.5);
    --rb-grad: linear-gradient(to bottom,
            #f2777a,
            #f99157,
            #ffcc66,
            #99cc99,
            #66cccc,
            #6699cc,
            #cc99cc);
}

.light-theme {
    --background-color: #efefef;
    --secondary-bg: #fff;
    --text: #222;
}

.App {
    margin: auto;
    max-width: 600px;
    padding: 20px 5% 0 5%;
    background-color: var(--secondary-bg);
    color: var(--text);
    overflow-x: hidden;
    border-style: solid;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    -webkit-border-image: var(--rb-grad) 0 0 0 100%;
    -o-border-image: var(--rb-grad) 0 0 0 100%;
    border-image: var(--rb-grad) 0 0 0 100%;
    -webkit-transition: ;
    -moz-transition: ;
    -ms-transition: ;
    -o-transition: ;
    transition: color 1s 0.2s linear;
}

.gradient-bg {
    background-image: var(--rb-grad);
    background-attachment: fixed;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lang-btn {
    font-size: inherit;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    color: #f2777a;
    text-decoration: underline;
    cursor: pointer;
    padding: .2rem;
    transition: color .5s linear;
    -webkit-transition: ;
    -moz-transition: ;
    -ms-transition: ;
    -o-transition: ;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.lang-btn:hover {
    color: #66cccc;
}

.frame-btn {
    background: transparent;
    font-size: 0.9rem;
    text-decoration: underline;
    color: #1b8798;
    cursor: pointer;
    border: none;
}

.greeting {
    font-size: 4rem;
    font-weight: 600;
    font-family: sans-serif;
    color: var(--text);
}

.greeting h1 {
    margin: 0;
}

/* Socialclub SVG Banner */

.scl-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

svg.banner {
    font: Arial;
    font-size: 100px;
    color: #282c34;
    text-align: center;
}

.banner-secondary {
    font: Arial;
    opacity: 0.8;
    font-size: 70px;
    text-align: center;
    stroke: var(--text);
    fill: var(--text);
}

.text-copy {
    fill: none;
    stroke: transparent;
    stroke-dasharray: 6% 29%;
    stroke-width: 1px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear alternate;
    -webkit-animation: stroke-offset 5.5s infinite linear alternate;
}

.text-copy:nth-child(1) {
    stroke: #a1b56c;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.text-copy:nth-child(2) {
    stroke: #dc9656;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.text-copy:nth-child(3) {
    stroke: #1b8798;
    -webkit-animation-delay: -3s;
    animation-delay: -3s;
}

.text-copy:nth-child(4) {
    stroke: #ab4642;
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
}

@-webkit-keyframes stroke-offset {
    100% {
        stroke-dashoffset: -35%;
    }
}

@keyframes stroke-offset {
    100% {
        stroke-dashoffset: -35%;
    }
}

/** webaudio card */

.music-section {
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 400px;
    max-height: 400px;
}

.music-card {
    box-shadow: var(--shadows);
}

.music-section:after {
    content: '';
    padding-bottom: 100%;
}

.rdm-wa-card {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.rdm-wa-card>img {
    display: block;
    width: 100%;
    height: 100%;
}

.rdm-wa-card button {
    width: 60px;
    height: 60px;
}

.rdm-wa-card button,
.rdm-wa-card canvas {
    position: absolute;
    display: block;
}

.rdm-wa-card button {
    top: 0;
    right: 0;
}

.rdm-wa-card canvas {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
    background-color: transparent;
}

.rdm-wa-card-btn.stopped {
    background-image: url('/resources/play_fill.png');
    background-position: 3px;
}

.rdm-wa-card-btn.loading {
    background-image: url('/resources/load.png');
    animation: spin infinite 5s linear;
    -webkit-animation: spin infinite 5s linear;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rdm-wa-card-btn.playing {
    background-image: url('/resources/pause_fill.png');
}

.rdm-wa-card-btn {
    -webkit-box-shadow: 5px 5px 10px;
    box-shadow: 5px 5px 10px;
    border: 2px solid white;
    margin: 20px;
    border-radius: 100%;
    background-color: #000000;
    background-size: cover;
    opacity: 0.8;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.rdm-wa-card-btn:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

/** Socialclub */

.scl-form {
    width: 100%;
    max-width: 300px;
    margin: auto;
    padding: 1rem 0;
}

.scl-form-input {
    border: none;
    border-bottom: 1px solid #66cccc;
    -webkit-appearance: none;
    background: transparent;
    width: 100%;
    margin: auto;
    font-size: 1.2rem;
    color: var(--text);
    transition: border-bottom .2s ease-out;
    -webkit-transition: border-bottom .2s ease-out;
    -moz-transition: border-bottom .2s ease-out;
    -ms-transition: border-bottom .2s ease-out;
    -o-transition: border-bottom .2s ease-out;
}

.scl-form-input:focus {
    margin: 0px auto;
    border-bottom: 3px solid #fc4f4f;
    outline: 0;
}

.scl-form-success,
.scl-form-progress,
.scl-form-error {
    width: 100%;
    text-align: center;
    margin: auto;
}

.scl-form-progress {
    color: var(--text);
}

.scl-form-success {
    color: #99cc99;
}

.scl-form-error {
    color: #f2777a;
}
